import { useEffect, useRef } from 'react';

/**
 * Custom Effect Hook that is triggered on compontentDidUpdate
 * @param {Function} fn
 * @param {Array} inputs
 * @returns {void}
 */
export default function useDidUpdateEffect(fn, inputs) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
  }, inputs);
}
